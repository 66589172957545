<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Ganancias Ventas</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>INFORME GANANCIAS VENTAS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-3">
            <label for="sucursal"><strong>SUCURSAL: </strong></label>
            <Dropdown
              v-model="sucursalSelected"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-6">
            <label for=""><strong>NOMBRE PRODUCTO: </strong></label>
            <AutoComplete
              :dropdown="true"
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              placeholder="Escriba Nombre Producto"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }}</strong
                  ><span
                    ><strong> --- </strong
                    ><!-- </span>
                      {{ slotProps.item.fabricas.nombre_proveedor
                      }}<span><strong> ---</strong> --></span
                  >
                  {{ slotProps.item.fabrica_nombre }}
                </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-3">
            <label for="cajero_id"><strong>USUARIOS/CAJEROS</strong></label>
            <Dropdown
              v-model="userSeleccionado"
              :options="users"
              optionLabel="name"
              optionValue="id"
            >
            </Dropdown>
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_venta_desde"
              ><strong>FECHA VENTA DESDE: </strong></label
            >
            <Calendar
              id="fecha_venta_desde"
              v-model="fecha_venta_desde"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for="fecha_venta_hasta"
              ><strong>FECHA VENTA HASTA: </strong></label
            >
            <Calendar
              id="fecha_venta_hasta"
              v-model="fecha_venta_hasta"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-3">
            <label for=""><strong>ACCIONES: </strong></label>
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              v-tooltip.top="'Aplicar Filtros de Busqueda'"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
      <div class="mt-4">
        <DataTable
          ref="reporte_ganancias_ventas"
          key="id"
          :value="ventas_ganancias"
          responsiveLayout="scroll"
          :loading="enviando"
          class="p-datatable-sm"
          :filters="buscar"
          responsive="true"
          stripedRows
          showGridlines
          :rows="20"
          :paginator="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[20, 100, 500, 1000, 5000, 10000]"
          currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Ganancias Ventas"
        >
          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-start md:align-items-start"
            >
              <h5>
                <strong>Total Ganacias Venta:</strong>
                {{ convertirNumeroGermanicFormat(total_ganancias_ventas) }} Bs.
              </h5>
            </div>
            <div
              class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
            >
              <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="buscar['global'].value"
                  placeholder="Buscar..."
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Ganancias DescargarPDF' in auth.user.permissions"
                  label="PDF Imprimir"
                  icon="pi pi-file-pdf"
                  class="p-button-outlined p-button-danger mr-2"
                  v-tooltip.top="'Imprimir Reporte PDF'"
                  :loading="generandoPDF"
                  :disabled="generandoPDF"
                  @click="generarPDF"
                />
              </span>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <Button
                  v-if="'R_Ganancias DescargarEXCEL' in auth.user.permissions"
                  label="EXCEL Exportar"
                  icon="pi pi-file-excel"
                  class="p-button-outlined p-button-success"
                  v-tooltip.top="'Imprimir Reporte Excel'"
                  :loading="exportando"
                  :disabled="exportando"
                  @click="exportVentas"
                />
              </span>
            </div>
          </template>
          <template #loading>
            <div class="flex align-items-center justify-content-center">
              <ProgressSpinner />
            </div>
          </template>
          <template #empty>
            <span
              class="flex align-items-center justify-content-center p-invalid"
              >Debe Aplicar Filtros para ver Resultados!</span
            ></template
          >
          <Column header="#">
            <template #body="slotProps">
              {{ 1 + slotProps.index }}
            </template>
          </Column>
          <Column field="id_venta" header="C.VENTA" :sortable="true" style="font-size: 0.8em;">
          </Column>
          <Column field="fecha_venta" header="F.VENTA" style="font-size: 0.8em;"> </Column>
         <!--  <Column field="nombre_sucursal" header="SUCURSAL">
            {{ data.sucursal }}
          </Column> -->
          <Column field="nombre_usuario" header="USUARIO" :sortable="true" style="font-size: 0.8em;">
            {{ data.usuario }}
          </Column>
          <Column
            field="nombre_laboratorio"
            header="MARCA"
            :sortable="true"
            style="font-size: 0.8em;"
          >
          </Column>
          <Column field="nombre_producto" header="PRODUCTO/DESCRIPCIÓN" style="font-size: 0.8em;"> </Column>
          <Column field="cantidad" header="CANTIDAD" style="font-size: 0.8em;"> </Column>
          <Column
            field="precio_compra"
            header="T.COMPRA"
            class="text-right"
            :sortable="true"
            style="font-size: 0.8em;"
          >
            <template #body="{ data }">
              {{
                convertirNumeroGermanicFormat(
                  obtenerTotalCompra(data.precio_compra, data.cantidad)
                )
              }}
            </template>
          </Column>
          <Column
            field="precio_venta"
            header="T.VENTA"
            class="text-right"
            :sortable="true"
            style="font-size: 0.8em;"
          >
            <template #body="{ data }">
              {{
                convertirNumeroGermanicFormat(
                  obtenerTotalVenta(data.precio_venta, data.cantidad)
                )
              }}
            </template>
          </Column>
          <Column
            field="ganancia_total"
            header="T.GANANCIA"
            class="text-right"
            :sortable="true"
            style="font-size: 0.8em;"
          >
            <template #body="{ data }">
              {{
                convertirNumeroGermanicFormat(
                  obtenerTotalGanancia(
                    data.precio_venta,
                    data.precio_compra,
                    data.cantidad
                  )
                )
              }}
            </template>
          </Column>
        </DataTable>
        <tfoot></tfoot>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import VentaService from "@/service/VentaService";
import SucursalService from "@/service/SucursalService";
import UserService from "@/service/UserService";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import { sum } from "lodash";
export default {
  data() {
    return {
      fecha_venta_desde: null,
      fecha_venta_hasta: null,
      enviando: false,
      ventas_ganancias: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      userSeleccionado: 0,
      users: [{ id: 0, name: "TODOS" }],
      generandoPDF: false,
      exportando: false,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      productoSelected: null,
      productosListadoFiltrado: [],
    };
  },
  ventaService: null,
  sucursalService: null,
  userService: null,
  auth: null,
  productService: null,
  created() {
    this.ventaService = new VentaService();
    this.sucursalService = new SucursalService();
    this.userService = new UserService();
    this.auth = useAuth();
    this.productService = new ProductService();
  },
  mounted() {
    this.cargarSucursales();
    this.cargarUsuarios();
  },
  computed: {
    total_ganancias_ventas() {
      return sum(
        this.ventas_ganancias.map((item) => {
          return (
            item.precio_venta * item.cantidad -
            item.precio_compra * item.cantidad
          );
        })
      );
    },
  },
  methods: {
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({ texto: event.query })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    obtenerTotalVenta(precio_venta, cantidad) {
      return precio_venta * cantidad;
    },
    obtenerTotalCompra(precio_compra, cantidad) {
      return precio_compra * cantidad;
    },
    obtenerTotalGanancia(precio_venta, precio_compra, cantidad) {
      return precio_venta * cantidad - precio_compra * cantidad;
    },
    exportVentas() {
      if (this.ventas_ganancias.length > 0) {
        let datos = {
          sucursal_id: this.sucursalSelected,
          cajero_id: this.userSeleccionado,
          fecha_venta_desde: this.fecha_venta_desde,
          fecha_venta_hasta: this.fecha_venta_hasta,
          producto: this.productoSelected ? this.productoSelected.id : null,
        };
        this.ventaService.exportarRGananciasVentas(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exportación exitosa",
            detail: "Se ha exportado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.ventas_ganancias.length > 0) {
        this.generandoPDF = true;
        let datos = {
          sucursal_id: this.sucursalSelected,
          cajero_id: this.userSeleccionado,
          fecha_venta_desde: this.fecha_venta_desde,
          fecha_venta_hasta: this.fecha_venta_hasta,
          producto: this.productoSelected ? this.productoSelected.id : null,
        };
        this.ventaService.imprimirRGananciasPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: "Se genero el PDF correctamente",
            life: 3000,
          });
        });
      } else
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
    },
    cargarUsuarios() {
      this.userService.getUsersAll().then((data) => {
        data.forEach((element) => {
          this.users.push(element);
        });
      });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((data) => {
          data.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    BuscarFiltro() {
      /* alert("AQUÍ PODRÁS VER LA GANANCIA DE CADA PRODUCTO VENDIDO, ESTAMOS TRABAJANDO EN ESTA PARTE"); */
      this.ventas_ganancias = [];
      this.enviando = true;
      this.cargarReporteVentas();
    },
    cargarReporteVentas() {
      let datos = {
        sucursal_id: this.sucursalSelected,
        cajero_id: this.userSeleccionado,
        fecha_venta_desde: this.fecha_venta_desde,
        fecha_venta_hasta: this.fecha_venta_hasta,
        producto: this.productoSelected ? this.productoSelected.id : null,
      };
      this.ventaService
        .filtrarGananciasVentas(datos)
        .then((data) => {
          this.ventas_ganancias = data.ventas_ganancias;
          this.enviando = false;
        })
        .catch((error) => {
          this.enviando = false;
          console.log(error);
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
}
</style>
